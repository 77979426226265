<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="120px"
    >
      <el-form-item label="场站" prop="stationId">
        <el-select
          v-model="dataForm.stationId"
          clearable
          filterable
          style="width: 100%"
          placeholder="请先选择场站"
          @change="stationChange"
        >
          <el-option
            v-for="item in stationList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="部门" prop="did">
        <el-select
          v-model="dataForm.did"
          clearable
          filterable
          @change="didChange"
          placeholder="请选择部门"
          style="width: 100%"
        >
          <el-option
            v-for="item in departmentList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="班组" prop="tid">
        <el-select
          v-model="dataForm.tid"
          clearable
          filterable
          @change="tidChange"
          style="width: 100%"
          :placeholder="dataForm.did ? '请选择班组' : '请先选择部门'"
        >
          <el-option
            v-for="item in teamList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <!-- <el-form-item label="检查人" prop="eid">
        <el-select
          v-model="dataForm.eid"
          clearable
          filterable
          style="width: 100%"
          :placeholder="dataForm.did ? '请选择检查人' : '请先选择部门'"
          @change="eidChange"
        >
          <el-option
            v-for="item in employeeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="检查计划" prop="patrolDescribe">
        <el-input
          type="textarea"
          rows="6"
          v-model="dataForm.patrolDescribe"
          placeholder="检查计划"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      departmentList: [],
      employeeList: [],
      teamList: [],
      stationList: [],
      dataForm: {
        id: 0,
        did: "",
        dname: "",
        tid: "",
        tname: "",
        stationId: "",
        stationName: "",
        eid: "",
        ename: "",
        patrolDescribe: "",
        createDate: "",
        updateDate: "",
        isDeleted: "",
      },
      dataRule: {
        did: [{ required: true, message: "部门不能为空", trigger: "blur" }],
        tid: [{ required: true, message: "班组不能为空", trigger: "blur" }],
        stationId: [
          { required: true, message: "场站不能为空", trigger: "blur" },
        ],
        // eid: [{ required: true, message: "检查人不能为空", trigger: "blur" }],
        patrolDescribe: [
          { required: true, message: "检查计划不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 选择的员工发生变化
    // eidChange(e) {
    //   this.dataForm.ename = "";
    //   if (e) {
    //     // 获取当前选择的员工名字
    //     let result = this.employeeList.filter((ele) => {
    //       return ele.id == e;
    //     });
    //     if (result.length > 0) {
    //       this.dataForm.ename = result[0].name;
    //     }
    //   }
    // },
    // 获取员工列表
    // getEmployeeList() {
    //   let employeeListDF = {
    //     did: this.dataForm.did,
    //     tid: this.dataForm.tid,
    //     page: 1,
    //     limit: 99999,
    //   };

    //   this.$http.employee.list(employeeListDF).then((res) => {
    //     if (res.data) {
    //       this.employeeList = res.data.list || [];
    //     } else {
    //       this.employeeList = [];
    //     }
    //   });
    // },
    // 选择的班组发生变化
    tidChange(e) {
      this.dataForm.tname = "";

      if (this.dataForm.tid) {
        if (e) {
          // 获取当前选择的班组名字
          let result = this.teamList.filter((ele) => {
            return ele.id == e;
          });
          if (result.length > 0) {
            this.dataForm.tname = result[0].name;
          }
        }
      } else {
        this.dataForm.eid = "";
      }
      this.employeeList = [];
      // this.getEmployeeList();
    },
    // 获取班组列表
    getTeamList() {
      let teamListDF = {
        did: this.dataForm.did,
        page: 1,
        limit: 99999,
      };

      this.$http.team.list(teamListDF).then((res) => {
        if (res.data && res.data.list && res.data.list.length > 0) {
          this.teamList = res.data.list;
        } else {
          this.teamList = [];
          this.dataForm.tid = "";
        }
      });
    },
    // 获取部门列表
    async getDepartmentList(e) {
      this.$http.department.list({ page: 1, limit: 99999 }).then((res) => {
        if (res.data) {
          this.departmentList = res.data.list || [];
        } else {
          this.departmentList = [];
        }

        // 修改页面
        if (e) {
          this.getTeamList();
        }
      });
    },
    // 选择的部门发生变化
    didChange(e) {
      if (e) {
        this.getTeamList();
        // this.getEmployeeList();

        this.dataForm.dname = "";

        // 获取当前选择的部门名字
        let result = this.departmentList.filter((ele) => {
          return ele.id == e;
        });
        if (result.length > 0) {
          this.dataForm.dname = result[0].name;
        }
      } else {
        this.teamList = [];
        this.dataForm.tid = "";
        this.employeeList = [];
        this.dataForm.eid = "";
      }
    },
    // 获取场站列表
    getStationList() {
      this.$http.station.list({ page: 1, limit: 99999 }).then((res) => {
        if (res.data) {
          this.stationList = res.data.list || [];
        } else {
          this.stationList = [];
        }
      });
    },
    // 选择的场站发生变化
    stationChange(e) {
      this.stationList.forEach((item) => {
        if (e == item.id) {
          this.dataForm.stationName = item.name;
        }
      });
    },
    init(id) {
      this.dataForm.id = id || 0;
      this.getStationList();
      this.getDepartmentList(id);
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (this.dataForm.id) {
          this.$http.patrolplan.info({ id: this.dataForm.id }).then((data) => {
            if (data && data.code === 0) {
              this.dataForm = data.data;
            }
          });
        }
      });
    },
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let requestDate = this.dataForm;

          if (this.dataForm.id) {
            // 修改
            this.$http.patrolplan.update(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "修改操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          } else {
            // 新增
            requestDate.id = "";
            this.$http.patrolplan.save(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          }
        }
      });
    },
  },
};
</script>
